import { Navigate } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import sessionRoutes from "./views/sessions/session-routes";
import administrationRoutes from "app/views/administration/AdministrationRoutes"
import userRoutes from "app/views/user/UserRoutes"
import dashboardRoutes from "app/views/dashboard/DashboardRoutes"


const routes = [
    {
        path: "/",
        element: <Navigate to="dashboard" />
    },
    {
        path: "*",
        element: <Navigate to="dashboard" />
    },
    {
        element: (
            <AuthGuard>
                <MatxLayout />
            </AuthGuard>
        ),
        children: [
            ...administrationRoutes,
            ...userRoutes,
            ...dashboardRoutes
        ]
    },

    // session pages route
    ...sessionRoutes
    ];

export default routes;
