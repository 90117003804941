import { lazy } from 'react'
import { Loadable } from 'app/components'


const AppDashboard = Loadable(lazy(() => import("app/views/dashboard/Dashboard")));


const dashboardRoutes = [
    {
        path: '/dashboard',
        exact: true,
        element: <AppDashboard />,
    },
]

export default dashboardRoutes