import React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import { styled } from '@mui/system'

const CardRoot = styled(Card)(() => ({
    height: '100%',
    padding: '10px 10px',
    marginBottom: '20px'
}))


const SimpleCard = ({ title,subheader, subheaderTypographyProps, action, children, color }) => {
    return (
        <CardRoot>
            <CardHeader
                action={ action }
                titleTypographyProps={{ fontSize: '1.5rem', fontWeight: '600', color: color || 'black' }}
                title={ title }
                subheaderTypographyProps = { subheaderTypographyProps }
                subheader={ subheader }
            />
            { children }
        </CardRoot>
    )
}

export default SimpleCard